@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #f5f5f5; /* Change this color to your desired background color */
}

.card {
  box-shadow: 0px 0px 15px #3e445726;
}
.ant-menu-item-selected,
.ant-menu-item-selected .ant-menu-item .ant-menu-item-selected {
  background-color: black !important;
}

.ant-table-thead th {
  padding: 12px 16px !important;
}

.ant-table-cell {
  padding: 8px 16px !important;
}
.ant-menu-item-selected::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 2%;
  background-color: #ff5612;
  transform: rotate(180deg);
}

.tableHeader {
  .ant-table-thead th {
    white-space: nowrap;
    background-color: black !important; /* Change to your desired background color */
    color: white !important;
    text-align: center !important;
  }
  .ant-tabs-nav {
    border: 5px solid black;
  }
}

.ant-tabs .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background-color: black !important;
}

.antdBorder {
  border: none !important;
}

.customCollapseError {
  .ant-collapse-header {
    background-color: #2e2d2d;
    border-radius: 8px 8px 0px 0px !important;
    padding: 7px 16px !important;
  }
  .ant-collapse-content-box {
    background-color: black !important;
    color: white;
  }
}

.customModal {
  .ant-modal-content {
    width: max-content !important;
  }
}

.antDBorderBottom {
  border-bottom: 1px solid #555555 !important;
}

.padding {
  .ant-collapse-content-box {
    background-color: #e5e7eb !important;
  }
}

.bgCollapse {
  .ant-collapse-content-box {
    background-color: #f1f1f1 !important;
  }
}

.cardShadow {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}

.paddingNone {
  .ant-collapse-content-box {
    padding: 0 !important;
  }
}

.drawerPaddingNone {
  .ant-drawer-body {
    padding: 0px !important;
  }
}

.inputTextCenter {
  .ant-input-number-input {
    text-align: center;
  }
  .ant-input-number-group-addon {
    padding: 0 !important;
  }
}

.stepTheme {
  .ant-steps-item-process .ant-steps-item-icon {
    background-color: #ff5612;
    border-color: #ff5612;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #ffcbb5;
    border-color: #ffcbb5;
  }
  .ant-steps-item-tail {
    border-color: black;
  }
  .anticon .anticon-check .ant-steps-finish-icon {
    color: black;
  }
  .ant-steps-item-title {
    font-size: small;
    font-weight: bold;
  }
}
.antdSelect {
  .ant-select-selector {
    border-radius: 0;
    border: 1px solid #5d646e !important;
  }
}

.removeArrow {
  .react-code-input {
    input[type="number"] {
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
    }
  }
}

.processBar {
  .ant-progress-bg {
    background-color: #ff5612;
    clip-path: polygon(0 0, 100% 0%, 95% 100%, 0% 100%);
    border-radius: 0% !important;
  }
  .ant-progress-inner {
    border-radius: 0% !important;
  }
}

.hideText {
  .react-pdf__Page__textContent {
    display: none !important;
  }
  .react-pdf__Page__annotations {
    display: none !important;
  }
}
.customScrollBar {
  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.customSelected {
  .ant-select-selection-item {
    height: 55px !important;
  }
}

.customCollapse {
  .ant-collapse-item {
    border: none !important;
    background-color: #f5f5f5 !important;
  }
  .ant-collapse-content {
    border: 0px !important;
  }
  .ant-collapse-content-box {
    background-color: #f5f5f5 !important;
    padding: 0 !important;
  }
}
